.leaflet-iconLayers {
    pointer-events: none;
}

.leaflet-iconLayers-layersRow { display: table; pointer-events: auto; }
.leaflet-iconLayers-layerCell { display: table-cell; background-image: url('transparent-pixel.png'); /* ie9 fix */ } 

.leaflet-iconLayers_topleft .leaflet-iconLayers-layerCell, .leaflet-iconLayers_bottomleft .leaflet-iconLayers-layerCell { padding-right: 5px; }
.leaflet-iconLayers_topright .leaflet-iconLayers-layerCell, .leaflet-iconLayers_bottomright .leaflet-iconLayers-layerCell { padding-left: 5px; }

.leaflet-iconLayers_topleft .leaflet-iconLayers-layerCell, .leaflet-iconLayers_topright .leaflet-iconLayers-layerCell { padding-bottom: 5px; }
.leaflet-iconLayers_bottomleft .leaflet-iconLayers-layerCell, .leaflet-iconLayers_bottomright .leaflet-iconLayers-layerCell { padding-top: 5px; }

.leaflet-iconLayers-layer {
    cursor: pointer;
    position: relative;
    width: 34px;
    height: 34px;
    background-color: rgba(0, 0, 0, 0.0);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    
    /* box-shadow: 0 0 5px #000; */
}

.leaflet-iconLayers-layerTitleContainer {
    display: table;
    width: 100%;
    background: rgba(255,255,255,0.5);
    height: 20%;
    padding: 0;
    border: 0;
    position: absolute;
    bottom: 0%;
    transition: bottom .35s ease;
}

.leaflet-iconLayers-layerCheckIcon {
    display: none;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 10px;
    height: 10px;
    background: url('check.png');
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 0.5px 0.5px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.6);
}

.leaflet-iconLayers-layerTitle {
    display: table-cell;
    vertical-align: middle;
}

.leaflet-iconLayers-layerCell_hidden { display: none; }
.leaflet-iconLayers-layerCell_active .leaflet-iconLayers-layer { cursor: default; }
.leaflet-iconLayers-layerCell_active .leaflet-iconLayers-layerCheckIcon { display: block; }