@import url('https://fonts.googleapis.com/css2?family=Cambay:ital,wght@0,400;0,700;1,700&family=Montserrat:wght@400;600;700&display=swap');

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 0;
  padding: 0;
}

.game {
  background: rgb(141, 59, 59);
  
  width: 100%;
}

#map {
  width: 100%;
  height: 94vh;
  
  background: "#F4F4F7";
}

#list {
  width: 100%;
  height: 94vh;
  
  background: "#F4F4F7";
}

#timer {
  margin-right: 20px;
  text-align: right;
  font-size: 20px;
}
#score { 
  text-align: right;
  font-size: 20px;
  margin-right: 20px;
}



@media screen and (max-width: 768px) {
  .parameterBox {
    width: 90%;
    margin-left: 5%;
  }
  .info {
    width: 90%;
    margin-left: 5%;
    font-size: 20px;
  }
  #countrySummary {
    bottom: 0px;
    width: 90%;
    height: 35%;
    margin-left: 5%;
    font-size: 20px;
  }
  #tripSummary {
    bottom: 0px;
    width: 90%;
    height: 35%;
    margin-left: 5%;
    font-size: 20px;
  }
  #tripPoint {
    bottom: 0px;
    width: 90%;
    height: 35%;
    margin-left: 5%;
    font-size: 20px;
  }
  .timerScore {
    margin-top: 100px;
    width: 100px;
  }
  #question {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (min-width: 768px) {
  .parameterBox {
    width: 50%;
    margin-left: 25%;
  }
  .info {
    width: 50%;
    margin-left: 25%;
    font-size: 34px;
  }
  #countrySummary {
    width: 20%;
    height: 80%;
    margin-left: 25%;
    font-size: 34px;
  }
  #tripSummary {
    width: 20%;
    height: 80%;
    margin-left: 25%;
    font-size: 34px;
  }
  #tripPoint {
    width: 20%;
    height: 80%;
    margin-left: 25%;
    font-size: 34px;
  }
  .timerScore {
    margin-top: 10px;
    width: 150px;
  }
  #question {
    width: 50%;
    margin-left: 25%;
  }
}

@media screen and (max-width: 1000px) {
  .labelsGroup {
    width: 96%;
  }
  .labels {
    width: 300px;
    height: 5vh;
    padding: 1.5vh;
    font-size: 1.5vh;
  }
}

@media screen and (min-width: 1000px) {
  .labelsGroup {
    width: 70%;
  }
  .labels {
    width: 350px;
    height: 8vh;
    padding: 3vh;
    font-size: 2vh;
  }
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(31,47,80,1);
}

.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 2px #33CC67 solid;
  border-radius: 25px;
}
.menu-item-wrapper:focus {
  outline-style: none;
}
.menu-item.active {
  border: 1px white solid;
}
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}